import React, { useState } from 'react';
import "../../App.css"
import Navbar from '../Navbar';
import Footer from '../Footer';

function EditProfile() {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    mobile: '',
    image: null,  // Initialize image as null
  });
  const [message, setMessage] = useState('');
  const token = localStorage.getItem('access_token');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      image: e.target.files[0],  // Handle the file input
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();  // FormData for file upload
    formDataToSend.append('first_name', formData.first_name);
    formDataToSend.append('last_name', formData.last_name);
    formDataToSend.append('mobile', formData.mobile);

    // Append image only if it's selected
    if (formData.image) {
      formDataToSend.append('image', formData.image);
    }

    try {
      const response = await fetch('https://ours.freecase99.xyz/user/profile/', {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,  // JWT Token
        },
        body: formDataToSend,  // Send FormData
      });

      if (response.ok) {
        setMessage('Profile updated successfully.');
      } else {
        const errorData = await response.json();
        setMessage(`Error: ${JSON.stringify(errorData)}`);
      }
    } catch (error) {
      setMessage('Error occurred while updating profile.');
    }
  };

  return (
    <div className='edit-profile'>
      <Navbar />
      <h2>Update Profile</h2>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        
        <label>Enter your first name</label><br></br>
        <input
          type="text"
          name="first_name"
          placeholder="First Name"
          value={formData.first_name}
          onChange={handleChange}
        /><br></br>


        <label>Enter your last name</label><br></br>
        <input
          type="text"
          name="last_name"
          placeholder="Last Name"
          value={formData.last_name}
          onChange={handleChange}
        /><br></br>


        <label>Enter your phone number</label><br></br>
        <input
          type="text"
          name="mobile"
          placeholder="Mobile"
          value={formData.mobile}
          onChange={handleChange}
        /><br></br>




        <input
          type="file"
          name="image"
          accept="image/*"
          onChange={handleFileChange}
        /><br></br>




        <button className='edit-btn' type="submit">Update Profile</button>
      </form><br></br>
      <p>{message}</p>
      <Footer />
    </div>
  );
}

export default EditProfile;
