import React from 'react'
import "../App.css"
import Navbar from './Navbar'
import Footer from './Footer'
import { useNavigate } from 'react-router-dom'


const VipPlan = () => {

  const navigate=useNavigate()

  const handleVip=()=>{
    navigate("vip-section")
  };
  const handleViptask=()=>{
    navigate("viptask")
  };
  const handleVipwithdraw=()=>{
    navigate("vipwithdraw")
  };

 

  return (
    <div className='vip'>
        <Navbar />
      <h2>Vip Plan</h2>
      <h1 onClick={handleVip}>ভিআইপি প্ল্যান বাই সেকশনে যান</h1>
      <h1 onClick={handleViptask}>ভিআইপি প্ল্যান টাচ সেকশন এ যান</h1>
      <h1 onClick={handleVipwithdraw}>vip withdraw পেজ এ যান</h1>
      <div className='card'>
        <p>Price: 500 tk,</p>
        <p>Daily Income: 20 tk,</p>
        <p>Duration: 60days</p>
        <button onClick={handleVip}>Buy now</button>
      </div>
      <div className='card'>
        <p>Price: 1000 tk,</p>
        <p>Daily Income: 40 tk,</p>
        <p>Duration: 60days</p>
        <button onClick={handleVip}>Buy now</button>

      </div>
      <div className='card'>
        <p>Price: 2000 tk,</p>
        <p>Daily Income: 70 tk,</p>
        <p>Duration: 60days</p>
        <button onClick={handleVip}>Buy now</button>

      </div>
      <div className='card'>
        <p>Price: 3000 tk,</p>
        <p>Daily Income: 110 tk,</p>
        <p>Duration: 60days</p>
        <button onClick={handleVip}>Buy now</button>

      </div>
      <div className='card'>
        <p>Price: 5000 tk,</p>
        <p>Daily Income: 185 tk,</p>
        <p>Duration: 60days</p>
        <button onClick={handleVip}>Buy now</button>

      </div>
      <div className='card'>
        <p>Price: 10000 tk,</p>
        <p>Daily Income: 380 tk,</p>
        <p>Duration: 60days</p>
        <button onClick={handleVip}>Buy now</button>

      </div>
      <div className='card' style={{ marginBottom:"10vh"}}>
        <p>Price: 20000 tk,</p>
        <p>Daily Income: 700 tk,</p>
        <p>Duration: 60days</p>
        <button onClick={handleVip}>Buy now</button>

      </div>

      <Footer />
    </div>
  )
}

export default VipPlan
