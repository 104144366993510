import React, { useState, useEffect } from 'react';
import TaskForm from './TaskForm';
import Navbar from './Navbar';
import Footer from './Footer';
import "../App.css"

const LastTask = () => {
  const [task, setTask] = useState(null);  // Store the last task
  const [loading, setLoading] = useState(true);  // For loading state
  const [error, setError] = useState(null);  // For error handling

  useEffect(() => {
    const accessToken = localStorage.getItem('access_token');  // Get access token from local storage

    fetch('https://ours.freecase99.xyz/tasks/', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,  // Include access token in the Authorization header
        'Content-Type': 'application/json',
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch task');
        }
        return response.json();
      })
      .then(data => {
        setTask(data);
        setLoading(false);
      })
      .catch(err => {
        setError(err.message);
        setLoading(false);
      });
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className='task1'>
      <Navbar />

      <h2 style={{color:'white', border:"1vh solid blue",borderRadius:"1VH"}} className='task11'>এই পেজটিতে কাজ হল যদি ইউটিউবের লিংক থাকে তাহলে সেই চ্যানেলটি সাবস্ক্রাইব করা। আর যদি facebook এর লিংক থাকে তাহলে সেটি ফলো করা এবং লাইক করা। যদি টিক টক বা টুইটার বা অন্য কোন কিছু দেওয়া থাকে তাহলে সেগুলো ফলো করা এবং তার প্রমাণস্বরূপ স্ক্রিনশট নিয়ে জমা দেওয়া। এর বাইরে যদি কোন টাচ দেওয়া হয় তাহলে তার বর্ণনা ওই টাস্ক এর সাথে দেওয়া থাকবে । কোন কিছু না বুঝতে পারলে হেল্পলাইনে যোগাযোগ করুন। এবং সততার সাথে কাজ করুন। আপনাদের যদি কোন সাবস্ক্রাইব লাগে, youtube facebook বা অন্য কোন সোশ্যাল মিডিয়াতে তাহলে আমাদের সাথে যোগাযোগ করতে পারেন।
যোগাযোগের জন্য হেল্প লাইন অপশন এ ক্লিক করে এডমিনের সাথে কথা বলুন।
আপনারা আমাদেরকে সাহায্য করুন তাহলে আপনাদের কেউ আমরা ভালো সেবা দিতে পারব। সবাই সৎ ভাবে কাজ করুন। যদি আপনাদের টাসপ্রুফ ভুল বলে প্রমাণিত হয় তাহলে আপনার ব্যালেন্স অটোমেটিক্যালি কেটে নেওয়া হবে এবং আপনার একাউন্ট সাসপেন্ড হয়ে যাবে। ধন্যবাদ।</h2>



      <div className="task-form">
      {task ? (
        <div>
          {
          <p>Click on the link below</p>
          /* <h3>Last Task</h3>
          
          <p><strong>Name:</strong> {task.name}</p>
          <p><strong>Created At:</strong> {new Date(task.created_at).toLocaleString()}</p> */}
          <p><strong>Link:</strong> <a href={task.link}>{task.link}</a></p>
        </div>
      ) : (
        <p>No task available</p>
      )}

      <p>Submit proof of your task</p>
      
      <TaskForm />
      </div>
      <Footer/>
    </div>
  );
};

export default LastTask;
