import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Login from './component/Login';
import PreHome from './component/PreHome';
import Homepage from './component/Homepage';
import Register from './component/Register';
import Logout from './component/Logout';
import LastTask from './component/LastTask';
import TaskPage from './component/TaskPage';
import Emailtask from './component/Emailtask';
import ActivationFeeForm from './component/ActivationFeeForm';
import VipPlan from './component/VipPlan';
import Decide from './component/Decide';
import SponsorList from './component/SponsorList';
import RequestPasswordReset from './component/Profile/RequestPasswordReset';
import ResetPasswordConfirm from './component/Profile/ResetPasswordConfirm';
import EditProfile from './component/Profile/EditProfile';
import HelpLine from './component/HelpLine';
import Withdraw from './component/Withdraw';
import VipPlanBuy from './Vip/VipPlanBuy';
import ViptaskSection from './Vip/ViptaskSection';
import VipWithdrawForm from './Vip/VipWithdreawForm';




function App() {
  return (
    <div className="App">
       <Router>
        <Routes>

          {/* Define routes for different components */}
          <Route path="/" element={<PreHome />} /> {/* Assuming PreHome is your landing page */}
          <Route path="/home" element={<Homepage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/logout" element={<Logout />} />
          <Route path='/task1' element={<LastTask />}/>
          <Route path='/taskpage' element={<TaskPage />}/>
          <Route path='/task2' element={<Emailtask />}/>
          <Route path='/activationfee' element={<ActivationFeeForm />}/>
          <Route path='/vip' element={<VipPlan />}/>
          <Route path='/decide' element={<Decide />}/>
          <Route path='/sponsor' element={<SponsorList />}/>
          <Route path='/requestpassreset' element={<RequestPasswordReset />}/>
          <Route path='/editprofile' element={<EditProfile />}/>
          <Route path='/help' element={<HelpLine />}/>
          <Route path='/withdraw' element={<Withdraw />}/>
          <Route path='/vip/vip-section' element={<VipPlanBuy />}/>
          <Route path='/vip/viptask' element={<ViptaskSection />}/>
          <Route path='/vip/vipwithdraw' element={<VipWithdrawForm />}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
