
import "../App.css"
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from "./Footer";

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleLogin = () => {
    // Create form data using URLSearchParams
    const formData = new URLSearchParams();
    formData.append('username', username);
    formData.append('password', password);

    // Use fetch() to send POST request
    fetch('https://ours.freecase99.xyz/auth/jwt/create', {
      method: 'POST',
      body: formData,  // Send the form data
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Login failed.');  // Handle errors
      }
      return response.json();  // Convert response to JSON
    })
    .then(data => {
      localStorage.setItem('access_token', data.access);
      localStorage.setItem('refresh_token', data.refresh);
      setMessage('Login successful!');
      navigate('/decide');  // Redirect to the homepage
    })
    .catch((error) => {
      console.error('Login failed:', error);
      setMessage('Login failed. Please check your credentials.');
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();  // Prevent form from refreshing the page
    handleLogin();
  };

  return (
    <div className='login'>
      <h2>Login Page</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="username">Username:</label><br></br>
          <input
            
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="password">Password:</label><br></br>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Login</button>
      </form>
      {message && <p>{message}</p>}

      <div className="blank">

      </div>
      <Footer/>
    </div>
  );
};

export default Login;
