// import React from 'react';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';


// const Logout = () => {
//   const navigate = useNavigate();

//   const handleLogout = () => {
//     const refreshToken = localStorage.getItem('refresh_token');

//     if (!refreshToken) {
//       console.error('No refresh token found.');
//       return;
//     }

//     fetch('http://localhost:8000/auth/logout/', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/x-www-form-urlencoded',
//       },
//       body: JSON.stringify({ refresh_token: refreshToken }),
//     })
//       .then(response => {
//         if (!response.ok) {
//           return response.json().then(errorData => {
//             throw new Error(errorData);
//           });
//         }
//         return response.json();
//       })
//       .then(() => {
//         localStorage.removeItem('access_token');
//         localStorage.removeItem('refresh_token');
//         console.log('Logout successful!');
//         navigate('/');  // Redirect to the homepage
//       })
//       .catch((error) => {
//         console.error('Logout failed:', error.message);
//       });
//   };

//   return (
//     <button onClick={handleLogout}>Logout</button>
//   );
// };

// export default Logout;


import React from 'react';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    const refreshToken = localStorage.getItem('refresh_token');

    if (!refreshToken) {
      console.error('No refresh token found.');
      return;
    }

    fetch('https://ours.freecase99.xyz/auth/jwt/refresh/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ refresh: refreshToken }),
    })
      .then(async (response) => {
        const contentType = response.headers.get('content-type');
        if (!response.ok) {
          if (contentType && contentType.includes('application/json')) {
            const errorData = await response.json();
            throw new Error(JSON.stringify(errorData));
          } else {
            throw new Error('Unexpected response format');
          }
        }
        return response.json();
      })
      .then(() => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        console.log('Logout successful!');
        navigate('/');  // Redirect to the homepage
      })
      .catch((error) => {
        console.error('Logout failed:', error.message);
      });
  };

  return (
    <button onClick={handleLogout}>Logout</button>
  );
};

export default Logout;
