import React from 'react'
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import "../App.css"
import Footer from './Footer';

const TaskPage = () => {
    const navigate = useNavigate();

    const handleTask=()=>{
        navigate("/task1")
    }
    const handleTask2=()=>{
        navigate("/task2")
    }

  return (
    <div className='taskpage'>
      <Navbar />

      <div onClick={handleTask} className='task'>
          <h2 onClick={handleTask}>Task 1</h2>
          <p>ফেসবুক ইউটিউব এবং tiktok এ লাইক ফলো সাবস্ক্রাইব নিতে+880 1704-822439 এই নাম্বারে  হোয়াটসঅ্যাপে যোগাযোগ করুন।আজকের টাস্ক হল ফেইস youtube বা tiktok follow এবং সাবস্ক্রাইব করা।</p>
          <p>আসসালামু আলাইকুম সবাইকে। সবাই ১০০% সততার সাথে কাজ করুন। ফ্রি কেস ৯৯ কে সহযোগিতা করুন তাহলে আপনারই লাভ। ধন্যবাদ।</p>
          <button>এখানে ক্লিক করুন</button>
      </div>

      <div className='task'>
          <h2>Task 2 (Coming soon)</h2>
          <p>দ্বিতীয় কাজটি হচ্ছে ইমেইল ক্রিয়েট করা। নিচের লিংকটিতে ক্লিক করলে আপনাকে একটি হোয়াটসঅ্যাপ গ্রুপে যুক্ত করা হবে। সেখানে সকল নির্দেশনা অনুসরণ করে কাজ করুন। প্রত্যেকটি কাজের জন্য পাঁচ টাকা করে যোগ হবে আপনার একাউন্টে। ধন্যবাদ</p>
          <button>এখানে ক্লিক করুন</button>

      </div>
      <Footer/>
    </div>
  )
}

export default TaskPage
