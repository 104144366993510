import React, { useState, useEffect } from 'react';
import "./Vip.css"

const WithdrawList = () => {
  const [withdrawals, setWithdrawals] = useState([]);
  const [error, setError] = useState(null);

  // Fetch list of withdrawals
  useEffect(() => {
    fetch('https://ours.freecase99.xyz/withdraw-list/', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`, // assuming token is stored in localStorage
        'Content-Type': 'application/json'
      },
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Failed to fetch withdrawal list");
      })
      .then(data => setWithdrawals(data))
      .catch(err => setError(err.message));
  }, []);

  // Cancel a withdrawal
  const cancelWithdrawal = (id) => {
    fetch(`https://ours.freecase99.xyz/cancel-withdraw/${id}/`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        'Content-Type': 'application/json'
      },
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Failed to cancel withdrawal");
      })
      .then(data => {
        if (data.detail === 'Withdrawal request canceled, and balance restored.') {
          alert('Withdrawal canceled successfully');
          setWithdrawals(withdrawals.filter(w => w.id !== id)); // Remove the canceled withdraw from the list
        } else {
          alert(data.detail); // Show error message if any
        }
      })
      .catch(err => {
        alert(err.message || 'Failed to cancel withdrawal');
      });
  };

  return (
    <div className='vip-withdraw-list'>
      <h2>Your Withdrawal Requests</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <ul>
        {withdrawals.length > 0 ? (
          withdrawals.map(withdraw => (
            <li key={withdraw.id}>
              <p>Amount: {withdraw.amount}</p>
              <p>Payment Method: {withdraw.payment_method}</p>
              <p>Status: {withdraw.payout_status ? 'Paid' : 'Pending'}</p>
              {!withdraw.payout_status && (
                <button onClick={() => cancelWithdrawal(withdraw.id)}>Cancel Withdrawal</button>
              )}
            </li>
          ))
        ) : (
          <p>No withdrawal requests found</p>
        )}
      </ul>
    </div>
  );
};

export default WithdrawList;
