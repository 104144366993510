import React, { useEffect, useState } from 'react';
import "../../App.css"

const WithdrawList = () => {
  const [withdrawals, setWithdrawals] = useState([]);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    fetchWithdrawals();
  }, []);

  const fetchWithdrawals = async () => {
    const token = localStorage.getItem('access_token'); 

    const response = await fetch('https://ours.freecase99.xyz/withdraw/list/', { 
      method:"GET", // Assuming there's an endpoint for listing withdrawals
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`,  // Assuming JWT token is stored in localStorage
      },
      
    });

    if (response.ok) {
      const data = await response.json();
      setWithdrawals(data);
    } else {
      setMessage('Failed to fetch withdrawal requests.');
    }
  };

  const cancelWithdraw = async (id) => {
    const token = localStorage.getItem('access_token'); 

    const response = await fetch(`https://ours.freecase99.xyz/withdraw/cancel/${id}/`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
      },
    });

    if (response.ok) {
      setMessage('Withdrawal request canceled successfully.');
      fetchWithdrawals();  // Refresh the list after cancellation
    } else {
      const data = await response.json();
      setMessage(`Error: ${data.error || 'Could not cancel the withdrawal.'}`);
    }
  };


  
  return (
    <div className='withdraw-list'>
      <h2>Your Withdrawal Requests</h2>
      {message && <p>{message}</p>}
      <ul>
        {withdrawals.map((withdraw) => (
          <li key={withdraw.id}>
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Amount</th>
                    <th>Payout Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{withdraw.name}</td>
                    <td>{withdraw.amount}</td>
                    <td>{withdraw.payout_status ? 'Processed' : 'Pending'} {!withdraw.payout_status && (
              <button onClick={() =>{
                console.log(withdraw.id);
                cancelWithdraw(withdraw.id) 
              } }>Cancel</button>
            )}</td>
                  </tr>
                </tbody>
              </table>

            {/* <p>{withdraw.name} - {withdraw.amount} - {withdraw.payout_status ? 'Processed' : 'Pending'}</p> */}
            
          </li>
        ))}
      </ul>
    </div>
  );
};

export default WithdrawList;
