// import React, { useState } from 'react';
// import axios from 'axios';
// import "../App.css"
// import Footer from './Footer';

// const Register = () => {
//   const [formData, setFormData] = useState({
//     username: '',
//     password: '',
//     email: '',
//     referred_by: "",
    
//   });

//   const handleChange = (e) => {
//     setFormData({
//       ...formData,
//       [e.target.name]: e.target.value,
//     });
//   };


//      // Check if passwords match
//      if (password !== confirmPassword) {
//       setError('Passwords do not match!');
//       setSuccess(false);
//       return;
//     }

//     // If passwords match, proceed with form submission
//     setError(null);
//     setSuccess(true);


//   const handleSubmit = (e) => {
//     e.preventDefault();
//     axios.post('http://127.0.0.1:8000/auth/users/', formData, {
//       headers: {
//         'Content-Type': 'application/json',
//       },
//     })
//     .then((response) => {
//       console.log('User registered:', response.data);
//     })
//     .catch((error) => {
//       if (error.response) {
//         console.error('There was an error!', error.response.data);
//       } else {
//         console.error('Error:', error.message);
//       }
//     });
//   };

//   return (
//     <div className='register'>
//       <h3>Welcome To Free Case 99</h3>
//       <p>Here is the Registration form for new user</p>
//     <form onSubmit={handleSubmit}>
      
//       <label className='label'>Enter your username</label><br></br>
//       <input type="text" name="username" placeholder="Username" onChange={handleChange} required /><br></br>

//       <label>Enter your email address</label><br></br>
//       <input type="email" name="email" placeholder="Email" onChange={handleChange} required /><br></br>

//       <label>Enter your Password</label><br></br>
//       <input type="password" name="password" placeholder="Password" onChange={handleChange} required /><br></br>

//       <label>Enter password again</label><br></br>
//       <input type="password" name="cpassword" placeholder=" Confirm Password" onChange={handleChange} required /><br></br>

//       <label>Enter refer code</label><br></br>
//       <input type="text" name="referred_by" placeholder="Referral Code (optional)" onChange={handleChange} /><br></br>
//       <button type="submit">Register</button>
//     </form>
//     <Footer />
//     </div>
//   );
// };

// export default Register;






import React, { useState } from 'react';
import axios from 'axios';
import "../App.css";
import Footer from './Footer';

const Register = () => {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    cpassword: '', // Confirm password field
    email: '',
    referred_by: "",
  });

  const [error, setError] = useState(null); // For displaying errors
  const [success, setSuccess] = useState(false); // For success status
  const [msg, setMsg] = useState(null); // For success status

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { password, cpassword, username, email, referred_by } = formData;

    // Check if passwords match
    if (password !== cpassword) {
      setError('Passwords do not match!');
      setSuccess(false);
      return;
    }

    // If passwords match, proceed with form submission
    setError(null); // Clear any previous errors

    // Create form data to send (exclude confirm password)
    const dataToSend = { username, password, email, referred_by };

    axios.post('https://ours.freecase99.xyz/auth/users/', dataToSend, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      console.log('User registered:', response.data);
      setSuccess(true);
      setMsg("User registration successfull") // Set success status
    })
    .catch((error) => {
      if (error.response) {
        console.error('There was an error!', error.response.data);
        setError(error.response.data); // Display the error from the response
      } else {
        console.error('Error:', error.message);
        setError('An error occurred while registering.');
      }
      setSuccess(false);
    });
  };

  return (
    <div className='register'>
      <h3>Welcome To Free Case 99</h3>
      <p>Here is the Registration form for new user</p>
      
      <form onSubmit={handleSubmit}>
        <label className='label'>Enter your username</label><br />
        <input
          type="text"
          name="username"
          placeholder="Username"
          value={formData.username}
          onChange={handleChange}
          required
        /><br />

        <label>Enter your email address</label><br />
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
          required
        /><br />

        <label>Enter your Password</label><br />
        <input
          type="password"
          name="password"
          placeholder="Password"
          value={formData.password}
          onChange={handleChange}
          required
        /><br />

        <label>Enter password again</label><br />
        <input
          type="password"
          name="cpassword"
          placeholder="Confirm Password"
          value={formData.cpassword}
          onChange={handleChange}
          required
        /><br />

        <label>Enter refer code</label><br />
        <input
          type="text"
          name="referred_by"
          placeholder="Referral Code (optional)"
          value={formData.referred_by}
          onChange={handleChange}
        /><br />

        <button type="submit">Register</button>
      </form>

      {/* {error && <div className="alert alert-danger mt-3">{error}</div>} */}
      {success && <div className="alert alert-success mt-3">Registration successful!</div>}
        <p>{msg}</p>
      <Footer />
    </div>
  );
};

export default Register;
