import React from 'react'
import VipTaskList from './Viptask'
import VipTaskProofUpload from './ViptaskForm'
import VipBalance from './VipBalance'
import Navbar from '../component/Navbar'

const ViptaskSection = () => {
  return (
    <div className='viptasksection'>
      <Navbar/>
       <VipTaskList/>
      <VipTaskProofUpload/>
      <VipBalance/>
    </div>
  )
}

export default ViptaskSection
