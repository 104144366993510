import React from 'react'
import RequestPasswordReset from './RequestPasswordReset'
import { useNavigate } from 'react-router-dom'
import Navbar from '../Navbar'

const Profile = () => {
    const navigate= useNavigate()

    const handlePasswordrestRequest=()=>{
        navigate("/requestpassreset")
    }

  return (
    <div>
        
      <p onClick={handlePasswordrestRequest}>Password reset</p>
    </div>
  )
}

export default Profile
