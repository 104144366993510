import { useParams } from 'react-router-dom';
import { useState } from 'react';

function ResetPasswordConfirm() {
  const { uid, token } = useParams();  // Extract uid and token from URL params
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');

  const handlePasswordReset = async () => {
    if (newPassword === confirmPassword) {
      try {
        const response = await fetch('https://ours.freecase99.xyz/users/reset_password_confirm/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            uid: uid,  // Use uid from URL
            token: token,  // Use token from URL
            new_password: newPassword,
            re_new_password: confirmPassword,
          }),
        });

        if (response.ok) {
          setMessage('Password reset successful.');
        } else {
          setMessage('Password reset failed.');
        }
      } catch (error) {
        setMessage('Error resetting password.');
      }
    } else {
      setMessage('Passwords do not match.');
    }
  };

  return (
    <div>
      <h2>Reset Password</h2>
      <input
        type="password"
        placeholder="Enter new password"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
      />
      <input
        type="password"
        placeholder="Confirm new password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
      />
      <button onClick={handlePasswordReset}>Reset Password</button>
      <p>{message}</p>
    </div>
  );
}

export default ResetPasswordConfirm;
