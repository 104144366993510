import React from 'react'
import WithdrawCreate from './Withdraw/WithdrawCreate'
import WithdrawList from './Withdraw/WithdrawList'
import Footer from './Footer'
import Navbar from './Navbar'

const Withdraw = () => {
  return (
    <div className='withdraw'>

        <Navbar />
        <div className="d">
            <h1>জরুরী নির্দেশনা</h1>
            <p>withdraw  রিকুয়েস্ট দেওয়ার আগে আপনি নিশ্চিত করুন যে আপনার তিনটি রেফার করা আছে।যদি আপনি নিশ্চিত হন যে আপনার কমপক্ষে তিনটি রেফার আছে  তাহলে আপনি withdraw বাটনে ক্লিক করে রিকোয়েস্ট দিন।রিকোয়েস্ট সাকসেসফুল হওয়ার 72 ঘন্টা পর্যন্ত অপেক্ষা করুন। এরপরেও পেমেন্ট না পেলে তাহলে সাপোর্টে যোগাযোগ করুন।</p>
            <p>আপনি যে পেমেন্ট মেথড সিলেক্ট করবেন অ্যাকাউন্ট নাম্বার বারবার ভালো করে দেখে নিবেন। একাউন্ট নাম্বার ভুল হলে ভুল একাউন্টে টাকা চলে গেলে এডমিন কর্তৃপক্ষ কোনোভাবেই দায়ী নয়। ধন্যবাদ</p>
        </div>
        
      <WithdrawCreate />
      <WithdrawList />
      <Footer />
    </div>
  )
}

export default Withdraw
