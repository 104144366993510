import React from 'react'
import Navbar from './Navbar'
import "../App.css"
import Footer from './Footer'

const Emailtask = () => {
  return (
    <div className='email-task'>
      <Navbar />
      <p>টাস্ক টু মূলত ইমেইল ক্রিয়েট করা। কাজটি করার জন্য আপনাকে গ্রুপে যুক্ত হতে হবে। সেখান থেকে পরবর্তী নির্দেশনা অনুসরণ করে কাজটি করে ফেলুন। টাস্ক টু এর টাকা আপনার ব্যালেন্সে ম্যানুয়ালি যুক্ত হবে ।</p>

      <a href='https://chat.whatsapp.com/EPcGkBw9mXeIHi3xQ9joAG'>click me</a>

      <Footer/>
    </div>
  )
}

export default Emailtask
