import React, { useEffect, useState } from 'react';

const UserBalance = () => {
    const [balance, setBalance] = useState(null);
    const [error, setError] = useState(null);

    // Replace with your actual token

    const fetchUserBalance = async () => {
        try {
            const token = localStorage.getItem('access_token'); 

            const response = await fetch('https://ours.freecase99.xyz/user/balance/', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,  // If using token authentication
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            setBalance(data.balance);  // Set the balance in state
        } catch (error) {
            setError(error.message);
            console.error("Error fetching user balance:", error);
        }
    };

    useEffect(() => {
        fetchUserBalance();  // Call the function on component mount
    }, []);

    return (
        <div>
            {error ? (
                <div>Error: {error}</div>
            ) : balance !== null ? (
                <div>Your Balance: {balance}</div>
            ) : (
                <div>Loading balance...</div>
            )}
        </div>
    );
};

export default UserBalance;
