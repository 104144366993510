import React from 'react'
import Navbar from './Navbar'
import UserReferrals from './Refer/ReferCode'
import Footer from './Footer'


const SponsorList = () => {
  return (
    <div className='sponsor'>
        <Navbar/>
        <UserReferrals />
        <Footer/>
    </div>
  )
}

export default SponsorList
