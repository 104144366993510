import React from 'react'
import tel from "../img/telegram.png"

import Menu from './Menu'
import Navbar from './Navbar'
import logo from "../img/logo-main.jpg"
import "../App.css"
import Footer from './Footer'
import { useState, useEffect } from 'react'


const Homepage = () => {

  const [referralCode, setReferralCode] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
      const fetchUserReferrals = async () => {
          try {
              const token = localStorage.getItem('access_token'); 

              const response = await fetch('https://ours.freecase99.xyz/api/referrals/', {
                  method: 'GET',
                  headers: {
                      'Authorization': `Bearer ${token}`,  // Use Bearer token for authentication
                      'Content-Type': 'application/json',
                  },
              });

              if (!response.ok) {
                  throw new Error(response.statusText); // Handle HTTP errors
              }

              const data = await response.json();
              setReferralCode(data.referral_code);
              
          } catch (err) {
              setError(err.message);
          } finally {
              setLoading(false);
          }
      };

      fetchUserReferrals();
  }, []);

  return (
    <div className='home-page'>
      <Navbar />
      <div>
        <Menu />
      </div>
      <div class="banner">
          <div class="banner-text">
              Welcome to Free Case 99! আসসালামু আলাইকুম সবাইকে। সম্মানিত গ্রাহক, ওয়েবসাইটে ভিজিট করার জন্য আপনাকে আন্তরিক শোভেচ্ছা। এখানে আপনি ১০০% সততার সাথে কাজ করুন। ফ্রি কেস ৯৯ কে সহযোগিতা করুন তাহলে আপনারই লাভ। ধন্যবাদ।!
          </div>
      </div>

      <img className='logo-img' src={logo} alt='logo'></img>


      <h1>Welcome to Home Page</h1>
      <p className='para'>Your referral code is: {referralCode}</p>

      <p>আমাদের অফিসিয়াল Telegram গ্রুপে জয়েন করতে নিচের বাটনটি ক্লিক করুন।</p>
      <img id='home-img' src={tel}></img>
      <button className='home-btn'><a href='https://t.me/+wAbKjoqA_59mNWNl'>জয়েন হতে এখানে ক্লিক করেন</a></button>

      <div className='bonus-details'>
        <h2>রেফার বোনাসের বিস্তারিত</h2>
        <p>আপনারা যদি রেফার করেন তাহলে পাঁচ জেনারেশন পর্যন্ত বোনাস পেতে থাকবেন।</p>
        <p>প্রথম জেনারেশন ইনকাম ৩০ টাকা।</p>
        <p>দ্বিতীয় জেনারেশন ইনকাম ২০ টাকা।</p>
        <p>তৃতীয় জেনারেশন ইনকাম দশ টাকা</p>
        <p>চতুর্থ জেনারেশন ইনকাম ৫ টাকা</p>
        <p>পঞ্চম জেনারেশন ইনকাম ৩ টাকা।</p>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default Homepage
