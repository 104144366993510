

import React, { useState } from 'react';
import "../../App.css";

const WithdrawCreate = () => {
  const [formData, setFormData] = useState({
    name: '',
    amount: '',
    payment_method: '',
    details: '',
  });
  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await fetch('https://ours.freecase99.xyz/withdraw/create/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`,  // Assuming JWT token is stored in localStorage
      },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      setMessage('Withdrawal request created successfully.');
      setFormData({
        name: '',
        amount: '',
        payment_method: '',
        details: '',
      });
    } else {
      const data = await response.json();
      setMessage(`Error: ${data.error || 'Something went wrong'}`);
    }
  };

  return (
    <div className='withdraw-create'>
      <h2>Create Withdrawal Request</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Username:</label><br /><br></br>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label>Amount:</label><br /><br></br>
          <input
            type="number"
            name="amount"
            value={formData.amount}
            onChange={handleChange}
            required
            min="300"
          />
        </div>

        <div>
          <label>Payment Method:</label><br /><br></br>
          <select
          style={{height: "4vh"}}
            name="payment_method"
            value={formData.payment_method}
            onChange={handleChange}
            required
          >
            <option className='big' value="">Select Payment Method</option>
            <option className='big' value="BKASH">BKASH</option>
            <option className='big' value="NAGAD">NAGAD</option>
            <option className='big' value="UPAY">UPAY</option>
          </select>
        </div><br></br>

        <div>
          <label>Account Number:</label><br /><br></br>
          <input
            type="text"
            name="details"
            value={formData.details}
            onChange={handleChange}
            required
          />
        </div>

        <button style={{paddingLeft:"3vh",
          paddingRight: "3vh",
          paddingTop: "1vh",
          paddingBottom: "1vh",
          backgroundColor: "yellowgreen",
          border: "1vh solid blue",
          borderRadius: "1vh",
          color: "white",
          marginTop: "2vh"
        }} type="submit">Withdraw</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default WithdrawCreate;
