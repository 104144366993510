import React, { useEffect, useState } from "react";
import "./Vip.css"

const VipBalance = () => {
  const [vipBalance, setVipBalance] = useState(null);
  const [error, setError] = useState(null);
  
  // Fetch VIP balance on component mount
  useEffect(() => {
    const fetchVipBalance = async () => {
      try {
        const token=localStorage.getItem("access_token")
        const response = await fetch('https://ours.freecase99.xyz/vip_balance_status/', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Add the JWT token for authentication
          }
        });
        
        if (!response.ok) {
          throw new Error('Error fetching VIP balance');
        }
        
        const data = await response.json();
        setVipBalance(data.vip_balance);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchVipBalance();
  }, []);

  return (
    <div className="vip-balance">
      <h2>Your VIP Balance</h2>
      {error ? (
        <p style={{ color: 'red' }}>{error}</p>
      ) : vipBalance !== null ? (
        <p>Your balance is: {vipBalance} tk</p>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default VipBalance;
