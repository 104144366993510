



// import React, { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';

// const Decide = () => {
//   const navigate = useNavigate();

//   useEffect(() => {
//     const token = localStorage.getItem('access_token'); 

//     fetch('http://localhost:8000/activation-fee/', {
//       method: 'GET',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${token}`
//       },
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         if (data.length > 0 && data[0].payment_status === true) {
//           // Assuming you are checking the first element in the array
//           navigate('/home');
//         } else {
//           navigate('/activationfee');
//         }
//       })
//       .catch((error) => console.error('Error fetching data:', error));
//   }, [navigate]);

//   return <div></div>;
// };

// export default Decide;




import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Decide = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('access_token'); 

    fetch('https://ours.freecase99.xyz/activation-fee/', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    })
      .then((response) => {
        if (!response.ok) {
          // If the response is not OK (like 401 Unauthorized or 404), we navigate to /activationfee
          throw new Error('Invalid response');
        }
        return response.json();
      })
      .then((data) => {
        // Check if data is an array and has the expected structure
        if (data.length > 0 && data[0].payment_status === true) {
          navigate('/home');
        } else if(data.length > 0 && data[0].payment_status === false){
          navigate('/activationfee');
        } else{
          navigate('/activationfee');
        }
      })
      .catch((error) => {
        console.error('Error fetching data or invalid response:', error);
        // In case of any error or invalid data, navigate to /activationfee
        navigate('/activationfee');
      });
  }, [navigate]);

  return <div></div>;
};

export default Decide;
