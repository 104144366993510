import React, { useState } from 'react';
import "./Vip.css"

const VipTaskProofUpload = () => {
  // State to handle the input data
  const [name, setName] = useState('');
  const [img, setImg] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Prepare form data to send
    const formData = new FormData();
    formData.append('name', name);
    formData.append('img', img);

    try {
      // Make the API request using fetch
      const response = await fetch('https://ours.freecase99.xyz/vip-task-proof/', {
        method: 'POST',
        headers: {
          // 'Content-Type': 'multipart/form-data' is handled automatically by FormData
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`, // JWT token for authenticated requests
        },
        body: formData,
      });

      const data = await response.json();

      if (response.ok) {
        setMessage('Task proof submitted successfully!');
      } else {
        setMessage(`Error: ${data.detail || 'Something went wrong'}`);
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="upload-container">
      <h2>Submit VIP Task Proof</h2>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div>
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>

        <div>
          <label htmlFor="img">Upload Image:</label>
          <input
            type="file"
            id="img"
            accept="image/*"
            onChange={(e) => setImg(e.target.files[0])}
            required
          />
        </div>

        <button type="submit" disabled={loading}>
          {loading ? 'Submitting...' : 'Submit Task Proof'}
        </button>
      </form>

      {message && <p>{message}</p>}
    </div>
  );
};

export default VipTaskProofUpload;
