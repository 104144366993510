// UserReferrals.js
import React, { useEffect, useState } from 'react';
import "../../App.css"
import Footer from '../Footer';

const UserReferrals = () => {
    const [referralCode, setReferralCode] = useState('');
    const [referredUsers, setReferredUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUserReferrals = async () => {
            try {
                const token = localStorage.getItem('access_token'); 

                const response = await fetch('https://ours.freecase99.xyz/api/referrals/', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,  // Use Bearer token for authentication
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error(response.statusText); // Handle HTTP errors
                }

                const data = await response.json();
                setReferralCode(data.referral_code);
                setReferredUsers(data.referred_users);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchUserReferrals();
    }, []); // Re-run if token changes

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className='sponsor'>

            <div className='bonus-details'>
                <h2>রেফার বোনাসের বিস্তারিত</h2>
                <p>আপনারা যদি রেফার করেন তাহলে পাঁচ জেনারেশন পর্যন্ত বোনাস পেতে থাকবেন।</p>
                <p>প্রথম জেনারেশন ইনকাম ৩০ টাকা।</p>
                <p>দ্বিতীয় জেনারেশন ইনকাম ২০ টাকা।</p>
                <p>তৃতীয় জেনারেশন ইনকাম দশ টাকা</p>
                <p>চতুর্থ জেনারেশন ইনকাম ৫ টাকা</p>
                <p>পঞ্চম জেনারেশন ইনকাম ৩ টাকা।</p>
            </div>

           <div className="code">
                <h1>Your Referral Code: {referralCode}</h1>
                <h2>Referred Users</h2>
                {referredUsers.length === 0 ? (
                    <p>No users referred yet.</p>
                ) : (
                    <ol>
                        {referredUsers.map(user => (
                            <li key={user.username}>
                                Username: {user.username} --- Referral Code: {user.referral_code}
                            </li>
                        ))}
                    </ol>
                )}
                    {/* <SuduRefer referralCode={referralCode}/> */}
            </div>
        </div>
    );
};

export default UserReferrals;
