import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import "../App.css"
import whatsapp from "../img/whatsapp.png"
import telegram from "../img/telegram.png"
import service from "../img/customer-service.png"

const HelpLine = () => {
  return (
    <div className='help'>
      <Navbar />

          <div className="section">
            <a href='https://t.me/+wAbKjoqA_59mNWNl'>
              <h2> 1) আমাদের টেলিগ্রাম গ্রুপে জয়েন হতে এখানে ক্লিক করুন</h2>
              <img className='img' src={telegram} alt='telegram-icon'></img>
            </a>
          </div>

          
          <div className="section">
            <a href='https://www.facebook.com/groups/518141284271491/?ref=share&mibextid=NSMWBT'>
              <h2> 2)facebook কমিউনিটিতে জয়েন হতে এখানে ক্লিক করুন</h2>
              <img className='img' src={service} alt='help-center-icon'></img>
            </a>
          </div>

          
          <div className="section" id='last'>
            <a href='https://chat.whatsapp.com/GutDcxAuz36GebI5kRZWei'>
              <h2 > 3)এক্টিভেশন ফি এবং উইথড্র সম্পর্কিত সকল সমস্যা সমাধানের জন্য 
              01785714943 এই হোয়াটসঅ্যাপ নাম্বারে যোগাযোগ করুন ।</h2>
              <img className='img' src={whatsapp} alt='whatsapp-icon'></img>
            </a>
          </div>

<Footer />
    </div>
  )
}

export default HelpLine
