import React, { useState, useEffect } from 'react';
import "../../App.css"

function GetUserProfile() {
  const [userProfile, setUserProfile] = useState(null);
  const [message, setMessage] = useState('');
  const token = localStorage.getItem('access_token');
  const first_name = localStorage.getItem('first_name'); 


  useEffect(() => {
    const last_name = localStorage.getItem('last_name'); 

    const fetchUserProfile = async () => {
      try {
        const response = await fetch('https://ours.freecase99.xyz/user/profile/', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setUserProfile(data);
          localStorage.setItem('data', data.first_name);
          localStorage.setItem('data', data.last_name);

        } else {
          setMessage('Error fetching profile data.');
        }
      } catch (error) {
        setMessage('Error occurred while fetching profile data.');
      }
    };

    fetchUserProfile();
  }, [token]);

  return (
    <div className='get-user-profile'>
      <h2>User Profile</h2>
      {userProfile ? (
        <div>
          <p><strong>Mobile:</strong> {userProfile.mobile}</p><br></br>

        
          {userProfile.image && (
            <img src={userProfile.image} alt="User" style={{ width: '100px', height: '100px' }} />
          )}
          <p>Dear {userProfile.first_name} -{userProfile.last_name}</p><br></br>
          


        </div>
      ) : (
        <p>Loading...</p>
      )}<br></br>
    </div>
  );
}

export default GetUserProfile;
