// import React, { useState, useEffect } from 'react';
// import "../App.css";


// const TaskForm = () => {
//   const [taskName, setTaskName] = useState('');
//   const [img, setImg] = useState(null);
//   const [isSubmitting, setIsSubmitting] = useState(false);
//   const [errorMessage, setErrorMessage] = useState('');

//   // useEffect(() => {
//   //   // Check if the user has already submitted within the last 24 hours
//   //   const lastSubmission = localStorage.getItem('lastSubmissionTime');
//   //   if (lastSubmission) {
//   //     const now = new Date().getTime();
//   //     const timeDifference = now - lastSubmission;

//   //     // If less than 24 hours (86400000 milliseconds), disable submission
//   //     if (timeDifference < 86400000) {
//   //       setIsSubmitting(true);
//   //       setErrorMessage('You can only submit once every 24 hours.');
//   //     }
//   //   }
//   // }, []);

//   const handleTaskNameChange = (e) => {
//     setTaskName(e.target.value);
//   };

//   const handleImgChange = (e) => {
//     setImg(e.target.files[0]);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     // If already submitted in the last 24 hours, prevent submission
//     if (isSubmitting) {
//       alert(errorMessage);
//       return;
//     }

//     // Create form data to send as multipart/form-data
//     const formData = new FormData();
//     formData.append('task_name', taskName);
//     formData.append('img', img);

//     try {
//       const token = localStorage.getItem('access_token'); 

//       // Submit the task form data
//       const response = await fetch('http://127.0.0.1:8000/proof_submit/', {
//         method: 'POST',
//         headers: {
//           // 'Content-Type': 'application/json',
//           'Authorization': `Bearer ${token}`

//         },
//         body: formData,  // Send form data
//       });

//       if (response.ok) {
//         const data = await response.json();
//         console.log('Task created successfully:', data);

//         // After successful task creation, increment the balance
//         const token = localStorage.getItem('access_token'); 

//         const balanceResponse = await fetch('http://127.0.0.1:8000/increment-balance/', {
//           method: 'POST',
//           headers: {
//             // 'Content-Type': 'application/json',
//             'Authorization': `Bearer ${token}`

//           },
//          // Include credentials for authenticated requests
//         });

//         if (balanceResponse.ok) {
//           const balanceData = await balanceResponse.json();
//           console.log('Balance updated:', balanceData);

//           // Set last submission time in localStorage
//           const now = new Date().getTime();
//           localStorage.setItem('lastSubmissionTime', now);

//           // Disable submission after successful task creation
//           setIsSubmitting(true);
//           setErrorMessage('You can only submit once every 24 hours.');
//         } else {
//           console.error('Error updating balance:', balanceResponse.statusText);
//         }
//       } else {
//         console.error('Error creating task:', response.statusText);
//       }
//     } catch (error) {
//       console.error('Error creating task:', error);
//     }
//   };

//   return (
//     <div className='task-form'>
//     <form onSubmit={handleSubmit} encType="multipart/form-data">
//       {errorMessage && <p style={{ color: 'blue' }}>{errorMessage}</p>}
//       <div>

//         <label>Task Name:</label><br></br>
//         <input
//           type="text"
//           value={taskName}
//           onChange={handleTaskNameChange}
//           required
//           disabled={isSubmitting}
//         />
//       </div>

//       <div>
//         <label>Upload proof Image:</label><br></br>
//         <input
//           type="file"
//           onChange={handleImgChange}
//           required
//           disabled={isSubmitting}
//         />
//       </div>

//       <button className='create-btn' type="submit" disabled={isSubmitting}>Create Task</button>
//     </form>
//     </div>
//   );
// };

// export default TaskForm;










import React, { useState } from 'react';
import "../App.css";

const TaskForm = () => {
  const [taskName, setTaskName] = useState('');
  const [img, setImg] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // Handle changes in the task name input field
  const handleTaskNameChange = (e) => {
    setTaskName(e.target.value);
  };

  // Handle image file selection
  const handleImgChange = (e) => {
    setImg(e.target.files[0]);
  };

  // Function to check the user's VIP status from the API
  const checkVipStatus = async () => {
    try {
      const token = localStorage.getItem('access_token');
      const vipResponse = await fetch('https://ours.freecase99.xyz/vip_balance_status/', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (vipResponse.ok) {
        const vipData = await vipResponse.json();
        return vipData.vip_status; // Return vip_status (true or false)
      } else {
        console.error('Error fetching VIP status:', vipResponse.statusText);
        return null; // Handle error by returning null
      }
    } catch (error) {
      console.error('Error fetching VIP status:', error);
      return null;
    }
  };

  // Handle form submission and VIP status check
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check vip_status before allowing submission
    const vipStatus = await checkVipStatus();

    // If vip_status is true, prevent form submission and balance update
    if (vipStatus === true) {
      setErrorMessage("VIP status is active, you cannot submit a task or update the balance.");
      alert(errorMessage); // Show an alert with the error message
      return;
    }

    // Proceed with form submission and balance update for all other cases
    const formData = new FormData();
    formData.append('task_name', taskName);
    formData.append('img', img);

    try {
      const token = localStorage.getItem('access_token');

      // Submit the task form data
      const response = await fetch('https://ours.freecase99.xyz/proof_submit/', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: formData, // Send form data as multipart/form-data
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Task created successfully:', data);

        // After successful task creation, update the balance
        const balanceResponse = await fetch('https://ours.freecase99.xyz/increment-balance/', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`
          },
        });

        if (balanceResponse.ok) {
          const balanceData = await balanceResponse.json();
          console.log('Balance updated:', balanceData);

          // Set last submission time in localStorage
          const now = new Date().getTime();
          localStorage.setItem('lastSubmissionTime', now);

          // Disable further submissions after successful task creation
          setIsSubmitting(true);
          setErrorMessage('You can only submit once every 24 hours.');
        } else {
          console.error('Error updating balance:', balanceResponse.statusText);
        }
      } else {
        console.error('Error creating task:', response.statusText);
      }
    } catch (error) {
      console.error('Error creating task:', error);
    }
  };

  return (
    <div className='task-form'>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        {errorMessage && <p style={{ color: 'blue' }}>{errorMessage}</p>}
        
        <div>
          <label>Task Name:</label><br></br>
          <input
            type="text"
            value={taskName}
            onChange={handleTaskNameChange}
            required
            disabled={isSubmitting}
          />
        </div>

        <div>
          <label>Upload proof Image:</label><br></br>
          <input
            type="file"
            onChange={handleImgChange}
            required
            disabled={isSubmitting}
          />
        </div>

        <button className='create-btn' type="submit" disabled={isSubmitting}>
          Create Task
        </button>
      </form>
    </div>
  );
};

export default TaskForm;











