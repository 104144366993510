// import React, { useState } from 'react';
// import "./Vip.css"

// const VipWithdrawForm = () => {
//   const [name, setName] = useState('');
//   const [amount, setAmount] = useState('');
//   const [paymentMethod, setPaymentMethod] = useState('');
//   const [details, setDetails] = useState('');
//   const [error, setError] = useState('');
//   const [success, setSuccess] = useState('');

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     // Clear previous messages
//     setError('');
//     setSuccess('');

//     const withdrawData = {
//       name,
//       amount,
//       payment_method: paymentMethod,
//       details,
//     };

//     try {
//       const response = await fetch('https://ours.freecase99.xyz/vip_withdraw/', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer ${localStorage.getItem('access_token')}`, // Use your token storage method
//         },
//         body: JSON.stringify(withdrawData),
//       });

//       if (!response.ok) {
//         const errorData = await response.json();
//         throw new Error(errorData.detail || 'Something went wrong!');
//       }

//       const result = await response.json();
//       setSuccess('Withdrawal request submitted successfully!');
      
//       // Clear the form
//       setName('');
//       setAmount('');
//       setPaymentMethod('');
//       setDetails('');
//     } catch (err) {
//       setError(err.message);
//     }
//   };

//   return (
//     <div className='vip-withdraw-form'>
//       <h2>VIP Withdraw Request</h2>
//       <form onSubmit={handleSubmit}>
//         <div>
//           <label>Name:</label>
//           <input
//             type="text"
//             value={name}
//             onChange={(e) => setName(e.target.value)}
//             required
//           />
//         </div>
//         <div>
//           <label>Amount:</label>
//           <input
//             type="number"
//             value={amount}
//             onChange={(e) => setAmount(e.target.value)}
//             required
//           />
//         </div>
//         <div>
//           <label>Payment Method:</label>
//           <input
//             type="text"
//             value={paymentMethod}
//             onChange={(e) => setPaymentMethod(e.target.value)}
//             required
//           />
//         </div>
//         <div>
//           <label>Details:</label>
//           <textarea
//             value={details}
//             onChange={(e) => setDetails(e.target.value)}
//             required
//           />
//         </div>
//         <button type="submit">Submit Withdrawal</button>
//       </form>
//       {error && <p style={{ color: 'red' }}>{error}</p>}
//       {success && <p style={{ color: 'green' }}>{success}</p>}
//     </div>
//   );
// };

// export default VipWithdrawForm;






import React, { useState } from 'react';
import "./Vip.css"
import WithdrawList from './WithdrawList';
import Navbar from '../component/Navbar';
import VipDirectin from './VipDirectin';
import VipBalance from './VipBalance';

const VipWithdrawForm = () => {
  const [name, setName] = useState('');
  const [amount, setAmount] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [details, setDetails] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false); // Loading state

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Clear previous messages
    setError('');
    setSuccess('');
    setLoading(true); // Set loading to true when the request starts

    const withdrawData = {
      name,
      amount,
      payment_method: paymentMethod,
      details,
    };

    try {
      const response = await fetch('https://ours.freecase99.xyz/vip_withdraw/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`, // Use your token storage method
        },
        body: JSON.stringify(withdrawData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Something went wrong!');
      }

      const result = await response.json();
      setSuccess('Withdrawal request submitted successfully!');
      
      // Clear the form
      setName('');
      setAmount('');
      setPaymentMethod('');
      setDetails('');
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false); // Set loading to false when request completes
    }
  };

  return (
    <div className='vip-withdraw-form'>
      <Navbar/>
      <VipBalance/>
      <VipDirectin/>
      <h2>VIP Withdraw Request</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            disabled={loading}
          />
        </div>
        <div>
          <label>Amount:</label>
          <input
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            required
            disabled={loading}
          />
        </div>
        <div>
          <label>Payment Method:</label>
          <select
            value={paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value)}
            required
            disabled={loading}
          >
            <option value="" disabled>Select Payment Method</option>
            <option value="bkash">Bkash</option>
            <option value="nagad">Nagad</option>
            <option value="upay">Upay</option>
          </select>
        </div>
        <div>
          <label>Account Number:</label>
          <textarea
            value={details}
            onChange={(e) => setDetails(e.target.value)}
            required
            disabled={loading}
          />
        </div>
        <button type="submit" disabled={loading}>
          {loading ? 'Submitting...' : 'Submit Withdrawal'}
        </button>
      </form>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {success && <p style={{ color: 'green' }}>{success}</p>}
      <WithdrawList/>
    </div>
  );
};

export default VipWithdrawForm;

