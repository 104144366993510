import React, { useState } from 'react';
import './Menu.css'; // Ensure the CSS is in the correct file
import { useNavigate } from 'react-router-dom';
import UserBalance from './UserBalance';
import Profile from './Profile/Profile';
import EditProfile from './Profile/EditProfile';
import GetUserProfile from './Profile/GetUserProfile';
 



const Menu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const navigate = useNavigate();

  const handleDashboard=()=>{
    navigate("/home")
  }

  const handleTask=()=>{
      navigate("/taskpage")
  }

  const handleWithdraw=()=>{
    navigate("/withdraw")
  }

  const handlevip=()=>{
    navigate("/vip")
  }

  const handleSponsor=()=>{
    navigate("/sponsor")
  }


 const handleEditProfile=()=>{
  navigate("/editprofile")
 }



  return (
    <div className="Menu">
      <button onClick={toggleMenu} className="menu-btn">
         Menu
      </button>
      <div className={`side-menu ${isMenuOpen ? 'open' : 'close'}`}>
        <GetUserProfile />
        {/* <h3>Welcome</h3> */}
        <Profile /><br></br>
        <p onClick={handleEditProfile}>Edit Profile</p><br></br>
        <p>Earnings</p>
        <div className='card'>
          <div>
            <p>Coming soon</p>
            <p>TOTAL</p>
          </div>
          <div>
            <p>Coming soon</p>
            <p>TODAY</p>
          </div>
        </div>
        <h2 onClick={handleDashboard}>DashBoard</h2>
        <ul>
          <li onClick={handleTask}>my jobs</li>
          <li>My Balance {<UserBalance />}</li>
          
          <li onClick={handlevip}>Vip plan</li>
          <li onClick={handleWithdraw}>Withdraw</li>
          <li onClick={handleSponsor}>Sponsor list</li>
          <li>Buy products</li>
          <li>Free click dot</li>
          <li>Email job</li>
        </ul>
      </div>
    </div>
  );
};

export default Menu;
