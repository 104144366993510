import React from 'react'
import Login from './Login'
import { useState } from 'react'
import Register from './Register'
import "../App.css"

const PreHome = () => {
    const [login, setLogIn]= useState(false)
    const handlelogin=()=>{
        setLogIn(!login)
    }

  return (
    <div className='prehome'>

     {
     
        login ? 
        <>
            <Register />
            <h3>Already registered ?</h3>

        </>
    
        : <Login />
        
    }

    <button onClick={handlelogin}>Clcick here to change SignUp or SignIn page</button>

      <div className='blank'>
        
      </div>
    </div>

  )
}

export default PreHome
