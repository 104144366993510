import React from 'react'
import "./Navbar.css"
import Menu from './Menu'


import dashboard from "../img/dashboard.png"
import helpline from "../img/headset.png"
import logout from "../img/logout.png"
import menu from "../img/menu.png"
import { useNavigate } from 'react-router-dom';


const Navbar = () => {

    const navigate = useNavigate();

    const handleDashboard=()=>{
      navigate("/home")
    }

    const handleHelpDesk=()=>{
      navigate("/help")
    }


    const handleLogout = () => {
        const refreshToken = localStorage.getItem('refresh_token');
    
        if (!refreshToken) {
          console.error('No refresh token found.');
          return;
        }
    
        fetch('https://ours.freecase99.xyz/auth/jwt/refresh/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ refresh: refreshToken }),
        })
          .then(async (response) => {
            const contentType = response.headers.get('content-type');
            if (!response.ok) {
              if (contentType && contentType.includes('application/json')) {
                const errorData = await response.json();
                throw new Error(JSON.stringify(errorData));
              } else {
                throw new Error('Unexpected response format');
              }
            }
            return response.json();
          })
          .then(() => {
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            console.log('Logout successful!');
            navigate('/');  // Redirect to the homepage
          })
          .catch((error) => {
            console.error('Logout failed:', error.message);
          });
      };
  return (
    <div className='navbar'>
      <ul className='nav-ul'>

        <div className='nav-element-element'>
          <img src={helpline}></img>
          <p onClick={handleHelpDesk}>Helpline</p>
        </div>

        <div className='nav-element-element'>
          <img src={dashboard}></img>
          <p onClick={handleDashboard}>DashBoard</p>
        </div>

        <div className='nav-element-element'>
          <img src={logout}></img>
          <button className='nav-btn' onClick={handleLogout}>Logout</button>
        </div>
      
      </ul>


      
    </div>
  )
}

export default Navbar
