import React from 'react'
import "./Vip.css"

const VipDirectin = () => {
  return (
    <div className='vip-d'>
      
            <p>500 tk vip plan mimimum withdraw - 500 tk </p>
            <p>1000 tk vip plan mimimum withdraw - 800 tk </p>
            <p>2000 tk vip plan mimimum withdraw - 1500 tk </p>
            <p>3000 tk vip plan mimimum withdraw -  2000 tk </p>
            <p>5000 tk vip plan mimimum withdraw- 3000 tk </p>
            <p>10,000 tk vip plan mimimum withdraw - 4500tk</p>
            <p> 20,000 tk vip plan mimimum withdraw - 8000tk</p>
    </div>
  )
}

export default VipDirectin
