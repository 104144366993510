import React, { useEffect, useState } from 'react';
import "./Vip.css"

const VipTaskList = () => {
  const [vipTasks, setVipTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch the data from the API when the component mounts
    const fetchVipTasks = async () => {
      try {
        const response = await fetch('https://ours.freecase99.xyz/vip_task/', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`, // Adjust token handling if needed
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch vip tasks');
        }
        const data = await response.json();
        setVipTasks(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchVipTasks();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div className='vip-task'>
      <h1>VIP Tasks</h1>
      <ul>
        {vipTasks.map((task) => (
          <li key={task.name}>
            <strong>{task.name}:</strong> <a href={task.link} target="_blank" rel="noopener noreferrer">{task.link}</a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default VipTaskList;
